/**
 * Membership form ajax functionality
 */

/**
 * Async membership form
 *
 * @author Dennis Bruinen
 */
var newsletter_form = function(){
    var self    = this;
    var $form   = $('.js-newsletter-form');
    var $submit = $('.js-newsletter-submit');

    self.init = function()
    {
        $form.submit(self.listenerFormSubmit);
    };

    /**
     * Asynchronous form submitting
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param jQuery object $form
     */
    self.listenerFormSubmit = function( e ){
        // Disable default functionality of the form
        e.preventDefault();
        // Disable the submit button, so doubleclick won't submit again. Reenable after ajax call completes
        $submit.attr('disabled','disabled');
        // Serialize data
        var data_array = $form.serialize();
        var $form_message = $('.js-form-message').length ? $('.js-form-message') : null;

        // Hide previous error message
        if( $form_message !== null ){
            $form_message.remove();
            $form_message = null;
        }

        $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            data: data_array,
            //Options to tell jQuery not to process data or worry about content-type.
            cache: false,
            //contentType: false,
            processData: false,
            async: true,
            success: function(data){
                if( data.status === 'success' ){
                    $form.before( self.createFormMessage( data.message, 'success') );
                    $form.slideUp();
                }else{
                    // Reenable submit button.
                    $submit.removeAttr('disabled');
                    // Reenable Google Recaptcha
                    grecaptcha.reset();
                    // Show error message
                    $form.before( self.createFormMessage( Lang.get('frontend.js.contact_request.error'), 'alert') );
                }

                $('html,body').animate( { scrollTop: $form.parentsUntil('footer').parent().offset().top - 100}, 1000 );
            },
            error: function(xhr){
                // Reenable submit button.
                $submit.removeAttr('disabled');
                // Reenable Google Recaptcha
                grecaptcha.reset();
                // If validation error occured
                if( xhr.status === 422 ){
                    var json = xhr.responseJSON;
                    var html = '';

                    for( var key in json ){
                        // Show every error on a new line
                        html += json[key] + '<br>';
                    }
                    // Show error message
                    $form.before( self.createFormMessage(html, 'alert') );
                }else{
                    // Show error message
                    $form.before( self.createFormMessage( Lang.get('frontend.js.contact_request.error'), 'alert') );
                }
                // Scroll to top of form
                $('html,body').animate( { scrollTop: $form.parentsUntil('footer').parent().offset().top - 100 }, 1000 );
            }
        });
        /**
         * Create form message
         *
         * @author Rianne Oosthoek <rianne@click.nl>
         * @param   {string}  message  message that we want to return to the user
         * @param   {string}  message_type  CSS class to determine the type of the message (success, warning, alert)
         * @return  {string}  string with HTML elements and the form message
         */
        self.createFormMessage = function(message, message_type){
            // Create HTML message layout
            var message_wrapper = '<div class="row column">' +
                                    '<div class="small-12 column">' +
                                        '<div class="alert-box ' + message_type + ' js-form-message">' +
                                            message +
                                        '</div>' +
                                    '</div>' +
                                '</div>';

            return message_wrapper;
        };
    };
};