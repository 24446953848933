/**
 * Select2
 */

var select2 = function(){
    var self = this;

    self.init = function(){
        $('.js-select2-tags').each(function(){
            var $el = $(this);
            // Check if this select hasn't been initialized yet
            if( $el.attr('data-select2-initialized') === 'false' ){
                // Initialize select2
                $el.select2({
                    tags: true,
                    language: 'nl',
                    placeholder: $(this).attr('data-placeholder'),
                    width: '100%',
                    createTag: function( params ){
                        // Check if the tag at least has ONE character when creating a new tag. We dont want to save only numbers, or our scripts will recognize it as a (false) ID
                        if( !params.term.match(/[a-zA-Z]/g) ){
                            return null;
                        }
                        return {
                            id: params.term,
                            text: params.term
                        };
                    }
                });
                // Set attribute so it won't get initialized again
                $el.attr('data-select2-initialized', 'true');
            }
        });
    };
};