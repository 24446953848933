window.form_animation = function(){
    var self = this;
    var $form = $('.js-form-animation');

    self.init = function(){

        // Check if one of the field already has a value, then it has been posted before
        $form.find('input,textarea,select').not('[type=hidden]').each(self.isAlreadyPosted);

        // Set listener on form
        $form.find('input,textarea,select').bind('change keyup input', self.isFormInUse);

    };
    /**
     * Check if form is used already so we can add/remove the valid class
     */
     self.isFormInUse = function(){
        // Check if form has changed and add/remove the class valid
        if( $(this).val() ){
            if( !$(this).hasClass('valid') ){
                $(this).addClass('valid');
            }
        }else{
            if( $(this).hasClass('valid') ){
                $(this).removeClass('valid');
            }
        }
    };

    /**
     * Check wether the form is posted
     */
     self.isAlreadyPosted = function()
     {
        var type = $(this).attr('type');
        if( ( $(this).val() !== '' && type !== 'radio' && type !== 'checkbox' ) || ( ( type === 'radio' || type === 'checkbox' ) && $(this).prop('checked') ) ){
            if( !$(this).hasClass('never') ){
                $(this).addClass('valid');
            }
        }
    };
}
