/**
 * Event  filtering
 * @author Dennis Bruinen <dennis@click.nl>, Edward van der Jagt <edward@caret.net>
 */
var event_filter = function(){
    var self                            = this;
    var reload_events                   = true;
    var base_url                        = 'event?';
    var class_type                      = '.js-filter-type';
    var class_type_active_filter        = ' option:selected';
    var class_date                      = '.js-filter-date';
    var class_date_active_filter        = ' option:selected';
    var class_category                  = '.js-filter-category';
    var class_category_active_filter    = '[class~=is-active]';
    var $item_overview                  = $('.js-event-overview');
    var $load_more_items                = $('.js-load-more-items');
    var css_loader                      = '<div class="c-loader js-loader"></div>';
    var $filter_head                    = $('.js-filter-head');
    var $custom_button                  = $('.js-custom-button');
    var $reset_filter                   = $('.js-filter-reset');
    var cnt_total_items                 = 0;

    self.init = function(session_filter, base) {
        if (base.length) {
            base_url = base;
        }

        // Prepare the filter with the given session or the existing querystring parameters
        self.prepareFilter(session_filter);

        // Set the listeners on the filter anchors.
        $(class_category).click(function(e){
            // Ignore the offical click
            e.preventDefault();
            // Add or remove the is-active class
            $(this).toggleClass('is-active');
            // Add or remove is-active class from $reset_filter element
            if( $reset_filter.hasClass('is-active') ){
                $reset_filter.removeClass('is-active');
            }else if( !$(class_category).hasClass('is-active') ){
                // Is no categories are selected, add is-active class to $reset_filter
                $reset_filter.addClass('is-active');
            }
            self.handleClick($(this));
        });

        $(class_type).change(function() {
            var $target = $(this).find('option:selected');
            self.handleClick($target);
        });

        $(class_date).change(function() {
            var $target = $(this).find('option:selected');
            self.handleClick($target);
        });

        // Set the listener of the load more button
        $load_more_items.on('click', function(e){
            // Ignore the offical click
            e.preventDefault();
            // Get more results
            self.getMoreResults();
        });

        // Set the listener for small screens, toggling filter options
        $filter_head.click(function(e){
            // Filter toggling is only visible on smaller screens
            if( $(window).width() < getBreakpointSize('medium') ){
                // Calculate the wanted height in rem
                var rem = $(this).parent('ul').height() / 10;
                if( rem === 4 ){
                    // Slide filter down
                    var anchor = $(this).parent('ul').find('[data-filter-column]');
                    var height = anchor.parent('li').outerHeight();
                    var cnt_li = anchor.length;
                    // + 4 rem for head and + 1 for a little marge space
                    var new_rem = ( height * cnt_li / 10 ) + 4 + 1;
                    $(this).parent('ul').css('height', new_rem + 'rem');
                    $(this).addClass('is-down');
                }else{
                    // Slide filter up
                    $(this).parent('ul').removeAttr('style');
                    $(this).removeClass('is-down');
                }
            }
        });

        // Reset the filter options height when resizing or changing the orientation
        $(window).on('resize orientationchange', function(){
            $filter_head.parent('ul').removeAttr('style');
        });

        // Set listener for resetting the filter
        $reset_filter.click(function(e){
            // Ignore default click
            e.preventDefault();

            var $target = $(this);
            // Only execute when element isn't active yet
            if( !$target.hasClass('is-active') ){
                // Set active
                $target.addClass('is-active');
                // Remove is-active classes so the filter data will be emptied
                $( class_category ).removeClass('is-active');
                // Update the results
                self.handleClick( $target );
            }
        });
    };

    self.handleClick = function ($target) {
        // Update the results
        self.updateResults();
    };

    /**
     * Method for getting a parameter from the querystrings by name
     *
     * @author Dennis Bruinen
     * @param  String name
     * @return String
     */
    self.getParameterByName = function( name ){
        var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
    };

    /**
     * Prepare the filter after loading, using the session
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.prepareFilter = function(session_filter) {
        // Build the filter_data object
        var filter_data = {};

        // Get the querystrings
        var t = self.getParameterByName('t');
        var c = self.getParameterByName('c');
        var d = self.getParameterByName('d');
        var reset = self.getParameterByName('reset');

        var querystrings_on_load = false;

        if (t !== '' && t !== null || c !== '' && c !== null || d !== '' && d !== null || reset === 'true') {
            // There is a querystring, set var so no session filter values but only querystirng parameters will be used
            // This is also used for resetting the filter
            querystrings_on_load = true;
        }

        // Get the types
        var arr_types = [];
        if (t !== '' && t !== null) {
            arr_types = t.split(',');
        } else if (session_filter.types !== undefined && !querystrings_on_load) {
            arr_types = session_filter.types.split(',');
        }

        // If any were selected, activate them in the filter
        if (arr_types.length > 0) {
            // Add is-active class to supposed to be active items
            for (var i = 0; i < arr_types.length; i++) {
                $(class_type).val(arr_types[i]);
            }
        }

        // Get the dates
        var arr_date = null;
        if (d !== '' && d !== null) {
            arr_date = d;
        } else if (session_filter.date !== undefined && !querystrings_on_load) {
            arr_date = session_filter.date;
        }

        // If any were selected, activate them in the filter
        if (arr_date !== null) {
            $(class_date + ' option[value="' + arr_date + '"]').attr('selected', 'selected');
        }

        // Get the categories
        var arr_categories = [];
        if (c !== '' && c !== null) {
            arr_categories = c.split(',');
        } else if (session_filter.categories !== undefined && !querystrings_on_load) {
            arr_categories = session_filter.categories.split(',');
        }
        // If any were selected, activate them in the filter
        if(arr_categories.length > 0) {
            // Add is-active class to supposed to be active items
            for (var i = 0; i < arr_categories.length; i++) {
                var $filter_item = $(class_category + '[data-filter-id="' + arr_categories[i] + '"]');
                $filter_item.addClass('is-active');
            }
        } else {
            // Add is-active class to reset filter button (all)
            $reset_filter.addClass('is-active');
        }

        self.updateResults();
    };

    /**
     * Update the results after filtering
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.updateResults = function(){
        // Build the filter data object
        var filter_data = self.buildFilterData();

        // We're getting new results, set offset to 0
        filter_data.offset = 0;
        // Update the url
        self.updateUrl( filter_data );
        // Get the items and insert them into the html
        self.getAndInsertItems( filter_data, false );
    };

    /**
     * Add results after loading more
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.getMoreResults = function(){
        // Build the filter data object
        var filter_data = self.buildFilterData();

        // Count the number of items we already have
        var cnt_items = $('.js-column').length;
        filter_data.offset = cnt_items;
        // Get the items and insert them into the html
        self.getAndInsertItems( filter_data, true );
    };

    /**
     * Build the filter data of the current state
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @return Object
     */
    self.buildFilterData = function(){
        // Build the filter_data object
        var filter_data = {};

        // Types
        var arr_types = [];
        // Get all active types and add them to filter_data
        $(class_type + class_type_active_filter).each(function() {
            if (!$(this).hasClass('js-placeholder-option')) {
                arr_types.push($(this).val());
            }
        });

        // Add to filter data
        filter_data.types = arr_types.join(',');

        // Date
        var date_filter = null;
        // Get the selected date and add it to filter_data
        $(class_date + class_date_active_filter).each(function() {
            if (!$(this).hasClass('js-placeholder-option')) {
                date_filter = $(this).val();
            }
        });

        // Add to filter data
        filter_data.date = date_filter;

        // Categories
        var arr_categories = [];
        // Get all active categories and add them to filter_data
        $(class_category + class_category_active_filter).each(function() {
            if ($(this).attr('data-filter-id') != undefined) {
                arr_categories.push($(this).attr('data-filter-id'));
            }
        });

        // Add to filter data
        filter_data.categories = arr_categories.join(',');

        return filter_data;
    };

    /**
     * Update the url with all the necessary querystring parameters
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  Object filter_data
     */
    self.updateUrl = function(filter_data) {
        // Create the new url
        var new_url = base_url
            + '&t=' + (filter_data.types !== null ? filter_data.types : '')
            + '&c=' + (filter_data.categories !== null ? filter_data.categories : '')
            + '&d=' + (filter_data.date !== null ? filter_data.date : '');

        // Replace the current url
        window.history.replaceState(null, null, new_url);
    };

    /**
     * Get the items and append or replace them
     *
     * @author Dennis Bruinen <dennis@click.nl>, Edward van der Jagt <edward@caret.net>
     * @param  Object filter_data
     * @param  Boolean append
     */
    self.getAndInsertItems = function(filter_data, append) {
        filter_data.reload_events = reload_events;
        reload_events = false;

        // Store the ajax call in a variable so we can call this whenever we want
        var ajax_func = function(){
            $.ajax({
                type: 'POST',
                url: '/api/event-filter',
                data: filter_data,
                // If we get something other than 200 (eg 500), success won't trigger, but complete will. So use success and not complete.
                success: function( data, status, xhr) {
                },
                complete: function( data, status) {
                    if (status == 'success') {
                        columnFadeIn('js-animate');
                    } else {
                        // If we had some kind of error, go to a "load more" state. So the user may try again.
                        $item_overview.html( '' );
                        // Show the load more button
                        $load_more_items.removeClass('hide');
                        // Hide the custom button
                        $custom_button.addClass('hide');
                    }
                },
            }).done(function( json ){
                if( json.status === 'OK' ){
                    // Add or replace the html
                    if( append ){
                        // Append html
                        $item_overview.append( json.html );
                    }else{
                        // Insert new html in default overview
                        $item_overview.html( json.html );
                    }
                    // If we generalize this function even further, we should call a user defined code for inserting/appending data.

                    // Handling load more button. Count the number of current items
                    var cnt_items = $('.js-column').length;
                    // Set global var fot cnt total items
                    cnt_total_items = json.cnt_total_items;
                    // Hide load more button when all items have been loaded into the page
                    // or when the view is set to map or list
                    if( cnt_total_items === cnt_items ){
                        // Hide the load more button
                        $load_more_items.addClass('hide');
                        // Show the custom button
                        $custom_button.removeClass('hide');
                    }else{
                        // Show the load more button
                        $load_more_items.removeClass('hide');
                        // Hide the custom button
                        $custom_button.addClass('hide');
                    }
                }else if( json.status === 'NO_EVENTS_FOUND' ){
                    $item_overview.html('<div class="u-margin-vertical">' + Lang.get('frontend.js.event.no_results') + '</div>');
                    // Hide the load more button
                    $load_more_items.addClass('hide');
                    // Show the custom button
                    $custom_button.removeClass('hide');
                } else {
                    // Api call had an unexpected status. Warning.
                }
            });
        };
        if( !append ){
            // Show loader
            $item_overview.html( css_loader );
            // We do not want to execute the ajax call right away
            setTimeout(ajax_func, 500);
        }else{
            ajax_func();
        }

    };

};
