/**
 * Cookie consent banner functionality
 *
 * @author Joost Ligthart <joost@click.nl>
 */
var cookie_consent = function () {

    var self = this;
    var $body = $('body')
    var $cookie_banner = $('.js-cookie-banner');
    var $init_button_container = $('.js-cookie-buttons-init');
    var $edit_button_container = $('.js-cookie-buttons-edit');
    var $edit_button = $('.js-cookie-edit-button');
    var $accept_button = $('.js-cookie-accept-button');
    var $reject_button = $('.js-cookie-reject-button');
    var $cookie_edit_form = $('.js-cookie-edit-form');
    var $open_banner_button = $('.js-open-cookie-consent');
    let $error_field = $('.js-cookie-error');

    var $cookie_value = null;

    self.init = function (is_cookie_consent) {
        // Set the global variable with the last known cookie value (if it has been set already)
        $cookie_value = is_cookie_consent;

        if ($cookie_value !== null) {
            // Cookie has been set already, set the value of the toggle.
            $cookie_edit_form.find('input[name=is_accepted]').prop('checked', $cookie_value);
        } else {
            // No cookie value has been set. So show the cookie banner.
            $body.addClass('u-disable-scroll');
            $cookie_banner.removeClass('hide');
        }

        // When the button to change cookie settings in the footer has been clicked
        $open_banner_button.on('click', function () {
            // Disable scrolling
            $body.addClass('u-disable-scroll');
            // Show the banner
            $cookie_banner.removeClass('hide');
            // Remove the inital view with 2 buttons and replace it with a form with toggle and edit button.
            $init_button_container.addClass('hide');
            $edit_button_container.removeClass('hide');
        });

        // OnClick events for the accept en reject buttons
        $accept_button.on('click', function (e) {
            e.preventDefault();
            self.setCookie(e, true)
        });
        $reject_button.on('click', function (e) {
            e.preventDefault();
            self.setCookie(e, false)
        });

        // When we form to edit cookie settings has been submitted, update the preferences
        $cookie_edit_form.on('submit', function (e) {
            e.preventDefault();

            self.updateCookiePreferences();
        });
    };

    /**
     * Set cookie value
     *
     * @author Joost Ligthart <joost@click.nl>
     * @param Event e
     * @param  boolean is_accepted
     * @return void
     */
    self.setCookie = function (e, is_accepted) {
        $(e.target).prop('disabled', true);
        $cookie_edit_form.find('input[name=is_accepted]').prop('checked', is_accepted);
        self.updateCookiePreferences();
    };

    /**
     * Save the cookie consent preferences
     *
     * @author Joost Ligthart <joost@click.nl>
     * @return void
     */
    self.updateCookiePreferences = function () {
        $error_field.addClass('hide');
        // Disable the button to avoid spamming
        $edit_button.prop('disabled', true);

        $.post('/api/cookie/consent', $cookie_edit_form.serialize())
            .done(self.successFullyPosted)
            .fail(self.catchErrors);
    };

    /**
     * After the form is successfully posted
     *
     * @author Joost Ligthart <joost@click.nl>
     * @return void
     */
    self.successFullyPosted = function () {
        // Since we don't know which button has been clicked, just enable all buttons.
        self.enableButtons();

        // Hide the cookie consent popup
        $cookie_banner.addClass('hide');

        // Reload the page if user changes the preferences to show/hidetracking elements
        if ($cookie_value !== $cookie_edit_form.find('input[name=is_accepted]').val()) {
            window.scrollTo(0, 0);
            location.reload();
        }

        // Remove the disable scroll class when the user hasn't changed cookie preferences
        $body.removeClass('u-disable-scroll');
    };

    /**
     * Catch the errors from the form submit
     *
     * @author Joost Ligthart <joost@click.nl>
     * @param  error
     * @return void
     */
    self.catchErrors = function (error) {
        self.enableButtons();

        $error_field.removeClass('hide');

        if (error.response.status === 422) {
            $error_field.text(Lang.get('frontend.js.global.422_error'));
        } else {
            $error_field.text(Lang.get('frontend.js.global.error'));
        }
    };

    /**
     * Enables all buttons
     *
     * @author Joost Ligthart <joost@click.nl>
     * @return void
     */
    self.enableButtons = function () {
        $edit_button.prop('disabled', false);
        $reject_button.prop('disabled', false);
        $accept_button.prop('disabled', false);
    };

};
