/**
 * Lightcase overlay
 */

var lightcase = function() {
    var self = this;

    self.initAlbumSlideshow = function(){
        $('.js-album-slideshow').lightcase({
            shrinkFactor: 0.88,
            transition: 'scrollHorizontal',
            speedIn: 400,
            speedOut: 400,
            maxWidth: 1400,
            maxHeight: 1020,
            slideshowAutoStart: false
        });
    };

    self.initVideoOverlay = function() {
        $('.js-video-overlay').lightcase({
            maxWidth: 940,
            maxHeight: 530,
            showSequenceInfo: false,
            shrinkFactor: 0.90
        });
    };

    /**
     * Set video url on lightcase anchor
     * Is being called in the loadContent method of /frontend/accordion.js
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @param   {jQuery selector}  $video_wrapper 
     * @param   {object}  video_object  id | type
     */
    self.setVideoUrl = function( $video_wrapper, video_object ) {
        var video_url;
        // Create embed url
        if (video_object.type === 'youtube') {
            video_url = '//www.youtube.com/embed/' + video_object.id;
        } else if (video_object.type === 'vimeo') {
            video_url = '//player.vimeo.com/video/' + video_object.id;
        }
        // Place embed url on lightcase anchor
        $video_wrapper.attr('href', video_url);
    };
};