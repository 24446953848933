/**
 * Disabling the submit button when clicked, useful for slow pages (because of api calls)
 *
 * @author Dennis Bruinen <dennis@click.nl>
 */
var hide_submit_button = function() {
    var self = this;

    self.init = function() {
        // When the submit button has been clicked
        $('.js-hide-submit-button').click(function(){
            // Get the button name
            var button_name = $(this).attr('data-button-name');
            // Show the corresponding loader
            $('.js-submit-loader[data-loader-name='+button_name+']').removeClass('hide');
            // Hide the button
            $(this).addClass('hide');
        });
    };
};