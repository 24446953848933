/**
 * Table generator
 *
 * @author Rianne Oosthoek <rianne@click.nl>
 * @credits https://datatables.net/manual/
 */
var table_generator = function() {
    var self = this;
    var $table = $('.js-generated-table');
    // Main configuration of datatables
    // For more options, visit: https://datatables.net/reference/option/
    var main_config = {
        /* Features */
        serverSide: false, // Filtering, paging and sorting calculations are all performed by a server.
        processing: false, // Display of a 'processing' indicator when the table is being processed
        stateSave: false, // Restore table state on page reload

        /* Options */
        // Define the table control elements to appear on the page and in what order
        // renderer: 'foundation', // Display component renderer types
        paging: false, // Hide pagination
        searching: false, // Hide search bar
        info: false, // Hide table information
        ordering: true, // Enable column ordering

        /* Callbacks */
        // Callback that defines how the table state is stored and where.
        // stateSaveCallback: function (oSettings, oData) {
        //     localStorage.setItem( 'DataTables'+document.location.pathname, JSON.stringify(oData) );
        // },
        // // Callback that defines where and how a saved state should be loaded.
        // stateLoadCallback: function () {
        //     return JSON.parse( localStorage.getItem('DataTables'+document.location.pathname) );
        // },

        /* Internationalisation */
        language : {
            emptyTable : Lang.get('ClickAdmin::backend.js.emptytable'), // Table has no records string
            info : Lang.get('ClickAdmin::backend.js.info',{attribute: '_TOTAL_'}), // Table summary information display string
            infoEmpty : Lang.get('ClickAdmin::backend.js.infoempty'), // Table summary information string used when the table is empty or records
            infoFiltered : '', // Appended string to the summary information when the table is filtered
            infoPostFix : '', // String to append to all other summary information strings
            loadingRecords : Lang.get('ClickAdmin::backend.js.loadingrecords') // Loading information display string - shown when Ajax loading data
        },

        /* Responsive */
        responsive: {
            breakpoints: [ // Set the breakpoints for a Responsive instance
                { 
                    name: 'mobile-l', // Mobile landscape
                    width: 640 
                },
                {
                    name: 'mobile-p', // Mobile portrait
                    width: 640
                }
            ]
        }
    };

    self.generateTable = function( $el, data = [] ){
        var table_type = $el.attr('data-table-type');
        var match_id = $el.attr('data-table-match-id');
        var extra_config;
        var table_config;

        // Add options per table type to the config
        if( table_type == 'start-times' ){
            extra_config = self.optionsStartTimesTable( match_id );
            // Combine config variables
            table_config = $.extend({}, main_config, extra_config);
        }else{
            extra_config = self.optionsResultsTable( data );
            // Combine config variables
            table_config = $.extend({}, main_config, extra_config);
        }
        var table_config = $.extend({}, main_config, extra_config);
          // Init datatables
        $el.dataTable(table_config);
    };

    /**
     * Options for start times table(s)
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @author Dennis Bruinen <dennis@click.nl>
     * @return  {object}  table specific options for datatables
     */
    self.optionsStartTimesTable = function( match_id ) {
        var extra_config = { 
            ajax: '/api/match/get-start-times/' + match_id, // Load data for the table's content from an Ajax source
            columns: [
                { data: 'time', width: '100px' },
                { data: 'hole', width: '70px' },
                { data: 'name' },
                { data: 'hcp', width: '60px' },
                { data: 'flight', width: '75px' },
            ]
        };
        return extra_config;
    };

    /**
     * Options for match results table(s)
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @author Dennis Bruinen <dennis@click.nl>
     * @return  {object}  table specific options for datatables
     */
    self.optionsResultsTable = function( data ) {
        var extra_config = {
            columns: [
                { data: 'nr', width: '60px' },
                { data: 'name' },
                { data: 'hcp', width: '60px' },
                { data: 'score', width: '70px' },
                { data: 'info', width: '75px' },
            ],
            data: data['data']
        };

        return extra_config;
    };
};