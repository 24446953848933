/**
 * Async form
 *
 */
window.form = function()
{
    var self          = this;
    var $form         = $('.js-form');
    var $errors       = $('.js-errors');
    var $recaptcha    = $('.js-recaptcha');
    var $loader       = $('.js-loader');
    var $form_button  = $('.js-form-button');
    var $current_form = null;

    self.init = function()
    {
        $form.on('submit', self.submitForm);
        $form.find('select, input').on('keyup keydown change', self.showRecaptcha);
    };

    self.submitForm = function(e)
    {
        e.preventDefault();
        var form_data = new FormData( $(this)[0] );
        $current_form = $(this);
        $('.js-error').text('');
        $errors.addClass('hide');
        $loader.addClass('is-active');
        $form_button.addClass('hide');
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: form_data,
            cache: false,
            contentType: false,
            processData: false,
            async: true,
            success: self.formSuccess,
            error: self.formError
        });
    };

    self.formSuccess = function(data)
    {
        $current_form.parent().find('.js-success').html(data.success).removeClass('hide');
        $loader.removeClass('is-active');

        // for hidden success messages
        if($current_form.parent().find('.js-success').hasClass('hide')) {
            $current_form.parent().find('.js-success').removeClass('hide');
        } else {
            $current_form.parent().find('.js-success').removeClass('hide');
        }

        $current_form.remove();
    };

    self.formError = function(error)
    {
        $form_button.removeClass('hide');
        $loader.removeClass('is-active');

        let errors = [];

        if (error.responseJSON) {
            errors = error.responseJSON;
        }
        if ($recaptcha[0]) {
            grecaptcha.reset();
        }

        // loop through every error
        for (let error in errors) {
            // loop through every array element in error
            for (var i = 0; i < errors[error].length; i++) {
                // Check if an recaptch error is found
                if (errors.recaptcha != null) {
                    // Find the recaptcha elements and show the error.
                    if ($current_form.children().hasClass('js-captcha')) {
                        $errors.find('[data-error-name="' + error + '"]').text(errors[error][i]);
                    }
                } else {
                    // If no recaptcha error is found, show the other errors.
                    $errors.find('[data-error-name="' + error + '"]').text(errors[error][i]);
                }
                $errors.removeClass('hide');
            }
        }
    };

    /**
     * Show the recaptcha
     * @author Bas Lokerman <bas@click.nl>
     * @return void
     */
    self.showRecaptcha = function()
    {
        $recaptcha.removeClass('hide');
    };
}
