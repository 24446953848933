/**
 * Filter for vacancies
 * @author Hasan Özaynaci <hasan@click.nl>
 */
window.filter_vacancy = function() {
    var self                    = this;
    var $check                  = $('.js-filter-input:checkbox');
    var $remove_all_filters     = $('.js-filter-delete-selection');
    var $vacancy_container      = $('.js-vacancy-container');
    var $loader                 = $('.js-loader');
    var $vacancy_overview       = $('.js-vacancy-overview');
    var $select_position        = $('.js-position-filter');
    var $select_location        = $('.js-location-filter');
    var $location_dropdown      = $('.js-location-dropdown');
    var $position_dropdown      = $('.js-position-dropdown');
    var $body                   = $('body');
    var $active_filter_items    = $('.js-active-filters');
    var $load_more_btn          = $('.js-load-more-vacancies');

    /**
     * Paremeter handler
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  String name
     */
    self.getParameterByName = function(name)
    {
        var par = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
        return par && decodeURIComponent(par[1].replace(/\+/g, ' '));
    };

    /**
     * Call functions at start
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  Object session_filter
     */
    self.init = function(session_filter)
    {
        var update_data = self.prepareFilter(session_filter);
        $check.on('change', self.setListenerCheckboxFilter);
        // Update results if not being called

        $body.on('click', '.js-remove-filter-btn', self.removeFilters);
        $remove_all_filters.on('click', self.removeAllFilters);

        $load_more_btn.on('click', self.loadMore);
    };

    /**
     * Checkbox and filter options handler
     *
     * @author Hasan Özaynaci <hasan@click.nl>
     */
    self.setListenerCheckboxFilter = function()
    {
        $check.each(function() {
            var id_value = $(this).attr('id');
            var filter_value = $("label[for='" + id_value + "']").text();

            if($(this).is(':checked')) {
                var checkbox_value = $(this).val();
                if($('.js-filter-item').filter('[data-filter-value="'+ filter_value + '"]').length === 0) {

                    // Add a new selected filter item (e.g. Amsterdam) to the list with active filters
                    var filter_item = $('<div class="c-filter-selected js-filter-item" data-id="'+ checkbox_value +'" data-filter-value="'+ filter_value +'">'+ filter_value +'<button type="button" class="js-remove-filter-btn c-filter-label-button">&#10005;</button></div>');
                    $active_filter_items.append(filter_item);
                }
            } else {
                // Unchecking a checkbox (active filter item from active filter list)
                $('.js-filter-item:contains('+ filter_value +')').remove();
            }
        });

        self.toggleRemoveAllFilterButton();
        self.updateResults(true);
    };

    /**
     * Button to remove a selected filter
     *
     * @author Hasan Özaynaci <hasan@click.nl>
     */
    self.removeFilters = function (e)
    {
        // Remove all elements corresponded to the remove button and checkbox
        var filter_item     = $(this).parents('.js-filter-item');
        var value_of_filter = filter_item.data('filter-value');

        $check.filter('[id="'+ value_of_filter +'"]').prop('checked', false);
        filter_item.remove();

        self.toggleRemoveAllFilterButton();
        self.setFilterData();
        self.updateResults(true);
    };

    /**
     * Toggle the 'remove all filters' button when filter items are active or not
     *
     * @return void
     */
    self.toggleRemoveAllFilterButton = function()
    {
        if($('.js-filter-item').length > 0) {
            $remove_all_filters.removeClass('hide');
        } else {
            $remove_all_filters.addClass('hide');
        }
    }

    /**
     * Delete all active filters
     *
     * @author Hasan Özaynaci <hasan@click.nl>
     */
    self.removeAllFilters = function()
    {
        $('.js-filter-item').remove();
        self.toggleRemoveAllFilterButton();
        $check.prop('checked', false);
        self.updateResults(true);
    };


    /**
     * Creating an filter object for values
     *
     * @author Hasan Özaynaci <hasan@click.nl>
     * @return filter with the filtered values from selected checkboxes
     */
    self.setFilterData = function()
    {
        var filter = {};
        filter.location = [];
        filter.job_type = [];
        // adding checked values to array
        $select_location.filter(':checked').each(function() {
            filter.location.push($(this).val());
        });

        $select_position.filter(':checked').each(function() {
            filter.job_type.push($(this).val());
        });
        return filter;
    };

    /**
     * Preparing the filter with possible session values, or querystrings
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param  Object session_filter
     */
    self.prepareFilter = function(session_filter)
    {
        var querystrings = window.location.search;
        // Update url if session filter is not empty and querystring is
        if(session_filter !== '' && querystrings === ''){
            self.updateUrl( session_filter, false );
        }
        // Retrieve the querystrings
        querystrings = window.location.search;
        // Querystrings in parameter when it is not empty
        if(querystrings !== ''){
            var parameter_ty    = self.getParameterByName( 'ty' );
            var parameter_lo    = self.getParameterByName( 'lo' );

            // Job type values
            if(parameter_ty !== null) {
                var ty_arr = parameter_ty.split(',');
                if(ty_arr.length > 0 && ty_arr[0] !== '') {
                    for( var i = 0; i < ty_arr.length; i++) {
                        if(!isNaN(parseInt(ty_arr[i]))) {
                            $select_position.filter('[value=' + ty_arr[i] + ']').trigger('click');
                        }
                    }
                }
            }
            // Location values
            if(parameter_lo !== null) {
                var lo_arr = parameter_lo.split(',');
                if(lo_arr.length > 0 && lo_arr[0] !== '') {
                    for( var i = 0; i < lo_arr.length; i++) {
                        if(!isNaN(parseInt(lo_arr[i]))) {
                            $select_location.filter('[value=' + lo_arr[i] + ']').trigger('click');
                        }
                    }
                }
            }
            self.setListenerCheckboxFilter();
            return true;
        }

        return false;
    };

    /**
     * Update the results after function option(s)
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.updateResults = function(push_state)
    {
        var filter = self.setFilterData();
        self.updateUrl(filter, push_state);
        self.getVacancies(filter, push_state);
    };

    /**
     * Keeping the url updated after each filter option
     *
     * @author Dennis Bruinen <dennis@click.nl>
     */
    self.updateUrl = function(filter, push_state)
    {
        // creating the url for for filter
        var new_url = '/vacatures'
        + '?ty=' + filter.job_type
        + '&lo=' + filter.location;

        if(push_state) {
            window.history.pushState(null, null, new_url);
        } else {
            window.history.replaceState(null, null, new_url);
        }
    };

    /**
     * Get and insert data
     *
     * @author Hasan Özaynaci <hasan@click.nl>
     * @param Object filter
     */
    self.getVacancies = function(filter, push_state)
    {
        if( push_state ){
            // Filter has changed, start over
            var offset = 0;
            var replace_html = true;
        }else{
            // Load more items
            // Count number of currently showed vacancies
            var offset = $('.js-vacancy-card').length;
            var replace_html = false;
        }

        $.ajax({
            type: 'POST',
            url: '/api/filter-vacancies?offset=' + offset,
            data: filter,
        }).done(function(json) {
            // Inserting results into the html
            if( replace_html ) {
                $vacancy_overview.html(json.html);
            }
            else {
                $vacancy_overview.append(json.html);
            }

            // Trigger interchange
            $vacancy_overview.find('img').each(function(){
                new Foundation.Interchange( $(this) );
            });

            if(json.load_more_btn) {
                $load_more_btn.removeClass('hide');
            } else {
                $load_more_btn.addClass('hide');
            }
        });
    };

    /**
     * Load more vacancy items when clicking on 'load more' button
     *
     * @param Event e
     * @return void
     */
    self.loadMore = function(e)
    {
        e.preventDefault();
        self.updateResults(false);
    }
};
