/**
 * Membership form ajax functionality
 */

/**
 * Async membership form
 *
 * @author Dennis Bruinen
 */
var membership_form = function(){
    var self = this;
 
    self.init = function( $form ){
        self.listenersLanguages();
        self.listenerHandicapFields();
        self.listenerFormSubmit( $form );
    };

    self.listenersLanguages = function(){
        // Dutch
        $('.js-membership-form-dutch').click(function(e){
            e.preventDefault();
            $('.js-english-label').addClass('hide');
            $('.js-dutch-label').removeClass('hide');
            $('.js-locale').val('nl');
            $('.en-items').addClass('hide');
            $('.nl-items').removeClass('hide');
        });

        // English
        $('.js-membership-form-english').click(function(e){
            e.preventDefault();
            $('.js-english-label').removeClass('hide');
            $('.js-dutch-label').addClass('hide');
            $('.js-locale').val('en');
            $('.en-items').removeClass('hide');
            $('.nl-items').addClass('hide');
        });
    };

    self.listenerHandicapFields = function(){
        // Check on load if checkbox is already checked
        if( $('.js-handicap-registration-field').prop('checked') ){
            $('.js-ngf-fields').slideDown(300);
        }

        $('.js-handicap-registration-field').change(function(){
            if( $(this).prop('checked') ){
                $('.js-ngf-fields').slideDown(300);
            }else{
                // Hide the div and empty fields
                $('.js-ngf-fields').slideUp(300);
                $('.js-handicap-field').val('');
            }
        });
    };

    /**
     * Asynchronous form submitting
     *
     * @author Dennis Bruinen <dennis@click.nl>
     * @param jQuery object $form
     */
    self.listenerFormSubmit = function( $form ){
        $form.submit(function(e){
            // Disable default functionality of the form
            e.preventDefault();
            // Disable the submit button, so doubleclick won't submit again. Reenable after ajax call completes
            $('.js-submit').attr('disabled','disabled');
            // Serialize data
            var data_array = $form.serialize();
            var $form_message = $('.js-form-message').length ? $('.js-form-message') : null;

            // Hide previous error message
            if( $form_message !== null ){
                $form_message.remove();
                $form_message = null; 
            }

            $.ajax({
                type: 'POST',
                url: '/api/membership',
                data: data_array, 
                //Options to tell jQuery not to process data or worry about content-type.
                cache: false,
                //contentType: false,
                processData: false,
                async: true,
                complete: function(data){
                    // Ajax call is complete. Reenable submit button.
                    $('.js-submit').removeAttr('disabled');
                    // Reenable Google Recaptcha
                    grecaptcha.reset();
                },
                success: function(data){
                    if( data.status === 'success' ){
                        // Redirect to step 2 (external) url
                        location.href = data.url;
                    } else {
                        // Reenable Google Recaptcha
                        grecaptcha.reset();
                        // Show error message
                        $form.before( self.createFormMessage( Lang.get('frontend.js.contact_request.error'), 'alert') ); 
                        // Scroll to top of form
                        $('html,body').animate( { scrollTop: $form.parentsUntil('section').parent().offset().top }, 2000 );
                    }
                },
                error: function(xhr){
                    if( xhr.status === 422 ){
                        var json = xhr.responseJSON;
                        var html = '';

                        for( var key in json ){
                            // Show every error on a new line
                            html += json[key] + '<br>';
                        }
                        // Show error message
                        $form.before( self.createFormMessage(html, 'alert') );
                    }else{
                        // Show error message
                        $form.before( self.createFormMessage( Lang.get('frontend.js.contact_request.error'), 'alert') ); 
                    }
                    // Scroll to top of form
                    $('html,body').animate( { scrollTop: $form.parentsUntil('section').parent().offset().top }, 2000 );
                }
            });
        });
    };

    /**
     * Create form message
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @param   {string}  message  message that we want to return to the user
     * @param   {string}  message_type  CSS class to determine the type of the message (success, warning, alert)
     * @return  {string}  string with HTML elements and the form message
     */
    self.createFormMessage = function(message, message_type){
        // Create HTML message layout
        var message_wrapper = '<div class="row">' +
                                '<div class="small-12 column">' +
                                    '<div class="alert-box ' + message_type + ' js-form-message">' +
                                        message + 
                                    '</div>' +
                                '</div>' +
                            '</div>';

        return message_wrapper;
    };
};
