/**
 * Handicap form ajax functionality
 */

/**
 * Async handicap form
 *
 * @author Dennis Bruinen (altered by Rianne Oosthoek, Edward van der Jagt)
 */
var handicap_form = function(){
    var self = this;
 
    self.init = function( $form ){        
        $form.submit(function(e){
            // Disable default functionality of the form
            e.preventDefault();
            // Disable the submit button, so doubleclick won't submit again. Reenable after ajax call completes
            $('.js-submit').attr('disabled','disabled');
            // Serialize data
            var data_array = $form.serialize();
            var $form_message = $('.js-form-message').length ? $('.js-form-message') : null;

            // Hide previous error message
            if( $form_message !== null ){
                $form_message.remove();
                $form_message = null; 
            }

            $.ajax({
                type: 'POST',
                url: '/api/handicap',
                data: data_array, 
                //Options to tell jQuery not to process data or worry about content-type.
                cache: false,
                //contentType: false,
                processData: false,
                async: true,
                complete: function(data){
                    // Ajax call is complete. Reenable submit button.
                    $('.js-submit').removeAttr('disabled');
                },
                success: function(data){
                    // Show success message
                    $form.before( self.createFormMessage( data.message, 'success' ) );
                    // Hide form
                    $form.addClass('hide');
                },
                error: function(xhr){
                    if( xhr.status === 422 ){
                        var json = xhr.responseJSON;
                        var html = '';

                        for( var key in json ){
                            // Show every error on a new line
                            html += json[key] + '<br>';
                        }
                        // Show error message
                        $form.before( self.createFormMessage(html, 'alert') );
                    }else{
                        // Show error message
                        $form.before( self.createFormMessage( Lang.get('frontend.js.contact_request.error'), 'alert') ); 
                    }
                }
            });
        });
    };

    /**
     * Create form message
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @param   {string}  message  message that we want to return to the user
     * @param   {string}  message_type  CSS class to determine the type of the message (success, warning, alert)
     * @return  {string}  string with HTML elements and the form message
     */
    self.createFormMessage = function(message, message_type){
        // Create HTML message layout
        var message_wrapper = '<div class="row">' +
                                '<div class="small-12 column">' +
                                    '<div class="alert-box ' + message_type + ' js-form-message">' +
                                        message + 
                                    '</div>' +
                                '</div>' +
                            '</div>';

        return message_wrapper;
    }
};
