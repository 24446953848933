/**
 * Scroll spy
 */

/**
 * Animates menu
 *
 * @author Rianne Oosthoek
 * @credits
 */
var scroll_spy = function(){
    var self = this;
    var $window = $(window);
    var $stick_nav = $('.js-sticky-nav');

    self.init = function(){
        // Previous scroll position
        var prev_scroll = 0;
        var current_scroll = 0;

        $window.scroll(function(){
            // Determine the scroll position
            current_scroll = $window.scrollTop();

            // When you haven't scrolled past the main nav
            if ( current_scroll < ( $stick_nav.height() * 1.5 ) ){
                $stick_nav.removeClass('is-fixed');

                // Reset previous scroll position
                prev_scroll = current_scroll;
            // When scrolled past the main nav and the scroll direction is down, hide menu
            }else if( prev_scroll < current_scroll ){
                $stick_nav.addClass('is-fixed');

                // Reset previous scroll position
                prev_scroll = current_scroll;
            // When scrolled past the main nav and the scroll direction is up, show menu
            }else if( prev_scroll > current_scroll ){
                // Reset previous scroll position
                prev_scroll = current_scroll;
            }
        });
    };
};