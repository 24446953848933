/**
 * All global form functionalities used on website and in admin
 *
 * @author Various
 */
window.file_upload = function(){
    var self = this;
    var $window = $(window);
    var $body = $('body');
    var $html = $('html');
    var $document = $(document);

    /**
     * Functionality to show which file has been uploaded in the form
     *
     * @author Rianne Oosthoek
     */
    self.initListenerFileChange = function(){
        // Remove listener
        $body.off('change','.js-file-input');
        // Listener on file input to get the number of files or the file name
        $body.on('change', '.js-file-input', function(){
            // Selected input field
            var $file_input = $(this);
            // Name attr of selected file input
            var $file_input_id = $file_input.attr('id');
            // Holder to place selected files in
            var $file_placeholder_input = $('.js-placeholder-file-input[data-file-field='+$file_input_id+']');
            var $empty_file_input = $('.js-empty-file-input[data-file-field='+$file_input_id+']');

            // Check if file-input value is present
            if( $file_input[0].files.length === 1 ){
                // Uploaded file is an attachment
                $file_placeholder_input.html('<span class="font-color-primary">' + $file_input.val() + '</span>');
                // Show link to remove file input
                $empty_file_input.removeClass('hide');
            }
        });

        // Remove listener
        $body.off('click','.js-empty-file-input');
        // Listener on link to remove file input
        $body.on('click', '.js-empty-file-input', function(){
            var $empty_file_input = $(this);
            var $file_input_id = $empty_file_input.attr('data-file-field');
            var $file_input = $('.js-file-input[id='+$file_input_id+']');
            var $file_placeholder_input = $('.js-placeholder-file-input[data-file-field='+$file_input_id+']');

            // Check if file-input value is present
            if( $file_input[0].files.length === 1 ){
                // Empty placeholder element
                $file_placeholder_input.html('');
                // Empty file-input value
                $file_input.val('');
                // hide link to remove file input
                $empty_file_input.addClass('hide');
            }
        });
    }
}

/**
 * Remove "C:\fakepath\" from file uploads
 *
 * @author Jordy Snijders
 */
window.remove_fakepath_from_file_uploads = function(){
    var self = this;

    self.init = function(){
        $('html').on('change', '.js-file-input', function() {
            // label
            var label_selector = 'label[data-file-field="' + $(this)[0].id + '"] > span'; // selector 1
            label_selector += ',div[data-file-field="' + $(this)[0].id + '"] > span.js-file-path-text'; // selector 2

            // file name without C:\fakepath\
            var new_value = $(this)[0].files[0].name;

            // when the user selects a file, the browser changes the text that is displayed
            // but we need the text to be ours
            // so, let's add a small delay to ensure that our actions don't coincide with the browser manipulating the DOM
            setTimeout(
                function() {
                    $(label_selector).text(new_value);
                },
                10
            );
        });
    }
}
