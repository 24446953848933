/**
 * Reservations
 * @author Edward van der Jagt
 */
var reservation_handler = function(){
    var self = this;
    var last_date;
    var last_players;
    
    self.init = function( ){

        /* time slot selector */
        $('.js-schedule-container').on( 'click', '.js-book', function() {
            var target_class = 'is-selected';
            var currently_selected = false;

            // Check if the clicked element was already is-selected
            if ( $(this).hasClass( target_class ) ) {
                currently_selected = true;
            }

            // remove classname on all other buttons
            $( 'button.'+target_class ).each( function() { 
                $(this).removeClass( target_class ); 
            });

            if (!currently_selected) {
                // add class-name on the current clicked button
                $( this ).addClass( target_class );
                // set hidden input element to the value of data-slot of this element
                // $( this ).parent('form').find('input[name="slot"]').val( $(this).attr('data-slot') ); // TODO Why does this not work ?
                $( this ).get()[0].form.slot.value = $(this).attr('data-slot');
            } else {
                // We just removed this button as being selected, so target value is now null
                $( this ).get()[0].form.slot.value = null;
            }

        });


        // Show booking informatie
        $('.js-schedule-container').on( 'click', '.js-show-slot-info', function() {
            var $reveal_window = $('.js-reveal-slot-info');
            var attr_date = $(this).attr('data-date');

            var $slot_info = $('.js-slot-info[data-date="'+attr_date+'"]');
            if( $slot_info.length > 0 ){
                $reveal_window.html( $slot_info.html() ).foundation('open');
            }
        });

        //
        // Load initial schedule for today
        //
        $(document).ready( function() {
            var target_date = $('.js-date').val();
            var target_players = $('.js-players').val();
            $('.js-schedule-container').load('/mijn-account/schedule/'+ target_date +'/'+ target_players);
            self.last_date = target_date;
            self.last_players = target_players;
        });

        //
        // Reload schedule based on date and players count
        //
        $('.js-date,.js-players').change( function() {
            var target_date = $('.js-date').val();
            var target_players = $('.js-players').val();
            //
            // Check if values have actually changed, as we don't want unneeded reloads of the schedule
            //
            if ( (target_date == self.last_date) && (target_players == self.last_players) ) {
                // Don't reload
            } else {
                $('.js-schedule-container').fadeOut().load('/mijn-account/schedule/'+ target_date +'/'+ target_players).fadeIn();
            }
            self.last_date = target_date;
            self.last_players = target_players;
        });

        //
        // Keep the feedback concerning the amount of players up to date
        //
        $('.js-players, .js-friends').change( function() {
            var target_players   = $('.js-players').val();
            var selected_players = 1+$( '.js-friends' ).find(':selected').length;
            var delta            = target_players - selected_players;
            if (delta == 0) {
                $('.js-add-golfers-count').html( '' );
            } else if (delta < 0) {
                $('.js-add-golfers-count').html( Lang.get('frontend.js.reservation.remove_players', { count: Math.abs(delta) }) );
            } else if (delta > 0) {
                $('.js-add-golfers-count').html( Lang.get('frontend.js.reservation.add_players', { count: Math.abs(delta) }) );
            }
        });

    };

};
