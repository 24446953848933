/**
 * Contact request ajax functionality
 */

/**
 * Async contact form
 *
 * @author Dennis Bruinen (altered by Rianne Oosthoek, Edward van der Jagt)
 */
var contact_request_form = function(){
    var self = this;

    var app_env = null;
    var is_cookie_consent = false;

    self.init = function( $form, app_env, is_cookie_consent ){
        self.app_env = app_env;
        self.is_cookie_consent = is_cookie_consent;

        $form.submit(function(e){
            // Disable default functionality of the form
            e.preventDefault();
            // Disable the submit button, so doubleclick won't submit again. Reenable after ajax call completes
            $('.js-submit').attr('disabled','disabled');
            // Serialize data
            var data_array = $form.serialize();
            var $form_message = $('.js-form-message').length ? $('.js-form-message') : null;

            // Hide previous error message
            if( $form_message !== null ){
                $form_message.remove();
                $form_message = null;
            }

            $.ajax({
                type: 'POST',
                url: '/api/contact-aanvraag',
                data: data_array,
                //Options to tell jQuery not to process data or worry about content-type.
                cache: false,
                //contentType: false,
                processData: false,
                async: true,
                complete: function(data){
                    // Ajax call is complete. Reenable submit button.
                    $('.js-submit').removeAttr('disabled');
                },
                success: function(data){

                    // When environment is production and cookie consent is given.
                    if( self.app_env === 'production' && self.is_cookie_consent == true) {
                        // Send a custom event to GA after succesful request.
                        gtag('event', 'contact_form_submission');
                    }

                    // Show success message
                    $form.before( self.createFormMessage( data.message, 'success' ) );
                    // Hide form
                    $form.addClass('hide');
                },
                error: function(xhr){
                    if( xhr.status === 422 ){
                        var json = xhr.responseJSON;
                        var html = '';

                        for( var key in json ){
                            // Check if value is an arry and a field has multiple errors
                            if(Array.isArray(json[key]) && json[key].length > 1) {
                                // Make sure the errors are on a new line
                                for( var error_key in json[key]) {
                                    html += json[key][error_key] + '<br>';
                                }
                            } else {
                                // Show every error on a new line
                                html += json[key] + '<br>';
                            }

                        }

                        // Show error message
                        $form.before( self.createFormMessage(html, 'alert') );
                    }else{
                        // Show error message
                        $form.before( self.createFormMessage( Lang.get('frontend.js.contact_request.error'), 'alert') );
                    }
                }
            });
        });
    };

    /**
     * Create form message
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @param   {string}  message  message that we want to return to the user
     * @param   {string}  message_type  CSS class to determine the type of the message (success, warning, alert)
     * @return  {string}  string with HTML elements and the form message
     */
    self.createFormMessage = function(message, message_type){
        // Create HTML message layout
        var message_wrapper = '<div class="row">' +
                                '<div class="small-12 column">' +
                                    '<div class="alert-box ' + message_type + ' js-form-message">' +
                                        message +
                                    '</div>' +
                                '</div>' +
                            '</div>';

        return message_wrapper;
    }
};
