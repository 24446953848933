/**
 * Signup request ajax functionality
 */

/**
 * Async signup form
 *
 * @author Dennis Bruinen (altered by Rianne Oosthoek, Edward van der Jagt)
 */
var signup_request_form = function(){
    var self = this;
 
    self.init = function( $form ){   
        // Toggle field blocks on load, if value is filled
        self.toggleFieldBlocks();

        // Listener for changing signup type selector
        $('.js-youth-signup-type').change(function(){
            self.toggleFieldBlocks();
        });

         $('.js-has-handicap').change(function () {

            var $fieldset_has_handicap = $('.js-fieldset-signup-training-has-handicap');

            if ($(this).is(':checked')) {
                $fieldset_has_handicap.removeClass('hide');
                return;
            } else {
                $('.js_handicap_number').val('');
                $fieldset_has_handicap.addClass('hide');

            }
        });

        // Listener for submitting the form     
        $form.submit(function(e){
            // Disable default functionality of the form
            e.preventDefault();
            // Disable the submit button, so doubleclick won't submit again. Reenable after ajax call completes
            $(".js-submit").attr('disabled','disabled');
            // Serialize data
            var data_array = $form.serialize();
            var $form_message = $('.js-form-message-wrapper').length ? $('.js-form-message-wrapper') : null;

            // Hide previous error message
            if( $form_message !== null ){
                $form_message.remove();
                $form_message = null; 
            }

            $.ajax({
                type: 'POST',
                url: '/api/signup-aanvraag',
                data: data_array,
                //Options to tell jQuery not to process data or worry about content-type.
                cache: false,
                //contentType: false,
                processData: false,
                async: true,
                complete: function(data){
                    // Ajax call is complete. Reenable submit button.
                    $(".js-submit").removeAttr('disabled');
                },
                success: function(data){
                    if(!data.message) {
                        // We got unexpected data, most likely a server side error or something like that. Show an error
                        $form.before( self.createFormMessage( Lang.get('frontend.js.signup_request.error'), 'alert') ); 
                    } else {
                        // Show success message
                        $form.before( self.createFormMessage( data.message, 'success' ) );
                        // Hide form
                        $form.addClass('hide');
                    }
                    $('html,body').animate( { scrollTop: $form.parentsUntil('section').parent().offset().top }, 2000 );
                },
                error: function(xhr){
                    if( xhr.status === 422 ){
                        var json = xhr.responseJSON;
                        var html = '';

                        for( var key in json ){
                            // Show every error on a new line
                            html += json[key] + '<br>';
                        }
                        // Show error message
                        $form.before( self.createFormMessage(html, 'alert') );
                    }else{
                        // Show error message
                        $form.before( self.createFormMessage( Lang.get('frontend.js.signup_request.error'), 'alert') ); 
                    }
                    $('html,body').animate( { scrollTop: $form.parentsUntil('section').parent().offset().top }, 2000 );
                }
            });
        });
    };

    /**
     * Create form message
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     * @param   {string}  message  message that we want to return to the user
     * @param   {string}  message_type  CSS class to determine the type of the message (success, warning, alert)
     * @return  {string}  string with HTML elements and the form message
     */
    self.createFormMessage = function(message, message_type){
        // Create HTML message layout
        var message_wrapper = '<div class="row js-form-message-wrapper">' +
                                '<div class="small-12 column">' +
                                    '<div class="alert-box ' + message_type + ' js-form-message">' +
                                        message + 
                                    '</div>' +
                                '</div>' +
                            '</div>';

        return message_wrapper;
    };

    self.toggleFieldBlocks = function(){
        var signup_type = $('.js-youth-signup-type').val();
        
        // Check if selector has value
        if( signup_type ){
            var $fieldsets = $('.js-fieldset');
            var $fieldset_all = $('.js-fieldset-signup-all');
            var $fieldset_youth_camp = $('.js-fieldset-signup-youth-camp');
            var $fieldset_introtraining = $('.js-fieldset-signup-introtraining');
            var $fieldset_training = $('.js-fieldset-signup-youthtraining');
            var $fieldset_flowinto_training = $('.js-fieldset-signup-flowinto-training');
            var $fieldset_golf_data = $('.js-fieldset-signup-golf-data');

            // Hide all blocks and empty fields
            $fieldsets.each(function(){
                var $this = $(this);
                $this.addClass('hide');

                $this.find('input:not([type=radio]):not([type=checkbox])').val('');
                $this.find('select').val('');
                $this.find('input[type=checkbox]').prop('checked', false);
                $this.find('input[type=radio]').prop('checked', false);
                $this.find('textarea').prop('checked', false);
            });

            $fieldset_all.removeClass('hide');
            
            // Show correct field block when selected value is changed or initialized
            switch( signup_type ){
                case 'jeugdkamp':
                    $fieldset_youth_camp.removeClass('hide');
                break;
                 case 'instromen':
                    $fieldset_flowinto_training.removeClass('hide');
                    $fieldset_golf_data.removeClass('hide');
                break;
                case 'introductietrainingen':
                    $fieldset_introtraining.removeClass('hide');
                    $fieldset_golf_data.removeClass('hide');
                break;
                case 'jeugdtrainingen':
                    $fieldset_training.removeClass('hide');
                    $fieldset_golf_data.removeClass('hide');
                    
                break;
               
            }
        }
    };

};
