/**
 * Accordion functionality for the golf courses
 */

var accordion = function(){
    var self = this;
    self.slide_speed = 400;
    self.video_instances_made = false;

    /**
     * Initialize accordion functionality
     *
     * @author  Rianne Oosthoek <rianne@click.nl>
     * @param   {string}  data_type - Type of the accordion (accordion or squeezebox)
     * @return void
     */
    self.init = function( data_type, allow_scrolling = true ) {
        $('.js-accordion-item').filter(data_type).each(function(){
            var $accordion_item = $(this);
            // Content which needs to be toggled
            var $accordion_content = $accordion_item.find('.js-accordion-content');

            // Check if content exists before setting events
            if( $accordion_content.length ){
                var $accordion_trigger = $accordion_item.find('.js-accordion-trigger');
                // Remove click event first before setting a new click event
                $accordion_trigger.off('click').on('click', function(e){
                    e.preventDefault();
                    // Select button that triggers the accordion to open because
                    // we want to toggle the visibility of this button
                    if( $(this).hasClass('trigger-open') ){
                        var $button_open = $(this);
                    }else{
                        var $button_open = $accordion_item.filter('.is-active').find('.js-accordion-trigger.trigger-open');
                    }

                    // Execute toggle
                    self.toggle( $accordion_content, $button_open, $accordion_item, data_type, allow_scrolling );
                });
            }
        });
    };

    /**
     * Toggles the selected content pane's open/close state.
     *
     * @author  Rianne Oosthoek <rianne@click.nl>
     * @param   {jQuery}  $accordion_content - jQuery object of the pane' to toggle ('.js-accordion-content')
     * @param   {jQuery}  $button_open - jQuery object of the button that opens the accordion ('.js-accordion-trigger.trigger-open')
     * @param   {jQuery}  $accordion_item - jQuery object of the complete accordion item ('.js-accordion-item')
     * @param   {string}  data_type - Type of the accordion (accordion or squeezebox)
     * @param   {string}  allow_scrolling - Allow scrollTo
     * @return void
     */
    self.toggle = function( $accordion_content, $button_open, $accordion_item, data_type, allow_scrolling ){
        // Check if parent has class is-active
        if( $accordion_content.closest('.js-accordion-item').hasClass('is-active') ){
            // Close accordion
            self.up( $accordion_content, $button_open, $accordion_item, allow_scrolling );
        }else{
            // Open accordion
            self.down( $accordion_content, $button_open, $accordion_item, data_type, allow_scrolling );
        }
    };

    /**
     * [down description]
     *
     * @author  Rianne Oosthoek <rianne@click.nl>
     * @param   {jQuery}  $accordion_content - jQuery object of the pane' to toggle ('.js-accordion-content')
     * @param   {jQuery}  $button_open - jQuery object of the button that opens the accordion ('.js-accordion-trigger.trigger-open')
     * @param   {jQuery}  $accordion_item - jQuery object of the complete accordion item ('.js-accordion-item')
     * @param   {string}  data_type - Type of the accordion (accordion or squeezebox)
     * @return void
     */
    self.down = function( $accordion_content, $button_open, $accordion_item, data_type, scroll_window ){
        // Check if other accordions are open
        var $current_active_item = $('.js-accordion-item' + data_type).filter('.is-active');
        var $current_active_content = $current_active_item.find('.js-accordion-content');

        // Check if there is an active item
        if( $current_active_item.length ){
            var $current_active_button = $current_active_item.find('.js-accordion-trigger.trigger-open');
            // Close current active accordion
            self.up( $current_active_content, $current_active_button, $accordion_item, false );
        }

        // Hide button
        $button_open.addClass('hide');

        // Load content, if present
        self.loadVideoContent( $accordion_content );

        // Open triggered accordion
        $accordion_content.slideDown(self.slide_speed, function(){
            $(this).closest('.js-accordion-item').addClass('is-active');
            // Check if scrolling is allowed
            if( scroll_window ){
                // Scroll the browser window to the open content
                self.scrollWindow( $accordion_content );
            }
        });

    };

    /**
     * Closes the accordion tab defined by $accordion_content.
     *
     * @author  Rianne Oosthoek
     * @param   {jQuery}  $accordion_content - jQuery object of the pane' to toggle ('.js-accordion-content')
     * @param   {jQuery}  $button_open - jQuery object of the button that opens the accordion ('.js-accordion-trigger.trigger-open')
     * @param   {jQuery}  $accordion_item - jQuery object of the complete accordion item ('.js-accordion-item')
     * @return void
     */
    self.up = function( $accordion_content, $button_open, $accordion_item, scroll_window ){
        // Show button
        $button_open.removeClass('hide');

        // Check if scrolling is allowed
        if( scroll_window ){
            // Scroll the browser window to the item that has just been closed
            self.scrollWindow( $accordion_item );
        }

        // Close accordion
        $accordion_content.slideUp(self.slide_speed, function(){
            $(this).closest('.js-accordion-item').removeClass('is-active');
        });
        self.video_instances_made = false;
    };

    /**
     * Load the content of the video when the accordion slides open
     *
     * @author  Rianne Oosthoek <rianne@click.nl>
     * @param   {jQuery}  $accordion_content - jQuery object of the pane' to toggle ('.js-accordion-content')
     * @return  void
     */
    self.loadVideoContent = function( $accordion_content ) {
        // Check if video if present
        if( $accordion_content.find('.js-video-overlay').length > 0 ){
            var content_opened = $accordion_content.attr('data-content-opened');

            // Check on data-attr of $accordion_content to prevent multiple initiations of the code
            if( content_opened === 'false' ){
                var $video_wrapper = $accordion_content.find('.js-video-overlay');

                // Only create instance once
                if( !self.video_instances_made ){
                    var embed_video_func = new embed_video();
                    var lightcase_func = new lightcase();
                    self.video_instances_made = true;
                }
                $video_wrapper.each(function(){
                    var $el = $(this);
                    var data_url = $el.attr('data-url');
                    var video_object = embed_video_func.parseVideo(data_url);

                    // Set thumbnail (/global/video-url.js)
                    embed_video_func.setThumbnailWebsite( $el, data_url );

                    // Set embed url (/frontend/lightcase.js)
                    lightcase_func.setVideoUrl( $el, video_object );
                });
            }
            // Set data-attr value to true so we know the code has been initialized
            $accordion_content.attr('data-content-opened', true);
        }
    };

    /**
     * Scroll browser window when accordion toggles
     *
     * @author  Rianne Oosthoek <rianne@click.nl>
     * @param   {String}  $target  Target element to which the window should scroll (either '.js-accordion-item' or '.js-accordion-content')
     * @return  void
     */
    self.scrollWindow = function( $target ) {
        // Set offset
        var target_offset = $target.offset().top;
        var menu_height = $('.js-sticky-nav').height();
        var scroll_offset = target_offset - menu_height;

        // Scroll down the window view
        // $('html, body').scrollTop( scroll_offset );
        $('html, body').animate({
            scrollTop: scroll_offset
        }, 300);
    };
};