/*
 * Gloval JavaScript
 *
 * All of your custom javascript can be placed below.
 * Do NOT forgot to add comments above the different functions or functionalities that need explaining.
 *
 */

var $window = $(window);
var $body = $('body');


/**
 * Get token from meta tag in header and add CSRF token to the ajax request
 *
 * @author Rick van der Burg <rick@click.nl>
 */
function prepareForAjax() {
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('meta[name="_token"]').prop('content')
        }
    });
}

prepareForAjax();

/**
 * Measure window width
 * Fallback for iOS: returns 0 when window.outerWidth is being used
 * Fallback for Safari: measures media query window width through document.documentElement.clientWidth
 *
 * @author Rianne Oosthoek
 */
function getWindowWidth(){
    if ($body.hasClass('is-touch') || $body.hasClass('is-safari')) {
        return $window.width();
    } else if ($body.hasClass('is-non-touch')) {
        return window.outerWidth;
    }
}

/**
 * Get value of given breakpoint based on Sass breakpoints
 *
 * @author Rianne Oosthoek
 * @param  {string} name of the breakpoint (small, medium, etc.)
 * @return {int}    breakpoint size in pixels
 */
function getBreakpointSize(size) {
    // Loop through Foundation breakpoints
    for (var i in Foundation.MediaQuery.queries) {
        var query = Foundation.MediaQuery.queries[i];

        if (size === query.name) {
            // Split media query string so only the em value remains
            var str = query.value;
            var split_str = str.split('min-width: ')[1];

            // Split string and parse em value to and get px value
            var query_value = ( parseFloat( split_str.split('em)')[0] ) * 16 );

            return query_value;
        }
    }
}

/**
 * Method for fade in animation of search/filter results
 *
 * @author Rianne Oosthoek
 * @param  {string} element class of the selected element
 */
function columnFadeIn( element ){
    var $elements = $('.is-invisible.' + element);
    var time_out = 0;

    // Set timeout so we have some delay to execute the animation
    setTimeout(function(){
        $elements.each(function() {
            var $el = $(this);
            time_out = time_out + 100;
            // Set another timeout so the columns appear one by one
            setTimeout( function(){
                $el.removeClass('is-invisible');
            }, time_out);
        });
    }, 250);
}