/**
 * faq section
 * @author Derk Jan Speelman <derkjan@click.nl>
 */
var faq = function() {
    var self = this;
    var $group_link = $('.js-faq-group-link');
    var $faq_group = $('.js-faq-group');
    var $search_form = $('.js-faq-search-form');

    /**
     * Initialize faq section functionality
     *
     * @author Derk Jan Speelman <derkjan@click.nl>
     * @return void
     */
    self.init = function() {
        $group_link.click(this.faqGroupClickListener)
        $search_form.submit(this.faqSearchFormSubmit)
    }

    /**
     * Show and hide faq groups
     *
     * @author Derk Jan Speelman <derkjan@click.nl>
     * @author Dennis Bruinen <dennis@click.nl>
     * @return void
     */
    self.faqGroupClickListener = function(e) {
        e.preventDefault();

        // Handle active class on anchors
        $group_link.removeClass('is-active');
        $(this).addClass('is-active');
        $('.js-faq-item').removeClass('hide');

        // Handle showing the correct questions holder
        var group_slug = $(this).attr('data-group');
        $faq_group.addClass('hide');
        $('.js-faq-group[data-group=' + group_slug + ']').removeClass('hide');
    }

    /**
     * Show and hide faq groups based on the search value
     *
     * @author Derk Jan Speelman <derkjan@click.nl>
     * @author Dennis Bruinen <dennis@click.nl>
     * @return void
     */
    self.faqSearchFormSubmit = function(e) {
        e.preventDefault();

        var form_data = new FormData(e.target);
        var form_props = Object.fromEntries(form_data);

        // Get the search value
        var search_value = form_props.keyword;
        if (search_value == undefined || search_value == '') {
            return;
        }

        // Format the search value
        search_value = search_value.trim().toLowerCase();

        // Reset classes
        $group_link.removeClass('is-active');
        $faq_group.addClass('hide');
        $('.js-faq-item').addClass('hide');
        $('.js-faq-no-results').addClass('hide');

        var questions = $('.js-faq-question');
        var answers = $('.js-faq-answer');

        let no_results = true;

        // Loop over possible search matches
        for (const array of [questions, answers]) {
            for (const item of array) {
                // Get the text value of the elements
                var text = item.textContent;

                if (search_value !== undefined && search_value !== '') {
                    text = text.trim().toLowerCase();

                    // Set classes for elements that matched the search value
                    if (text.includes(search_value)) {
                        $(item).removeClass('hide');
                        $(item).parent().removeClass('hide');
                        $(item).parents('.js-faq-group').removeClass('hide');

                        no_results = false;
                    }
                }
            }
        }

        // if no results are found, show the no_results_found message
        if (no_results) {
            $('.js-faq-no-results').removeClass('hide');
        }

        // Scroll to faq section after submitting search form
        self.scrollWindow( $('.js-faq-wrapper') );
    }

    /**
     * Scroll browser window to given element
     *
     * @author  Rianne Oosthoek <rianne@click.nl>
     * @param   {object}  $target  Target element to which the window should scroll
     * @return  void
     */
    self.scrollWindow = function( $target ) {
        // Set offset
        var target_offset = $target.offset().top;
        var menu_height = $('.js-sticky-nav').height();
        var scroll_offset = target_offset - menu_height;

        // Scroll down the window view
        $('html, body').animate({
            scrollTop: scroll_offset
        }, 300);
    };
}
