/**
 * Slider.js
 *
 * All functionality regarding slider
 * @author Rianne Oosthoek <rianne@click.nl>
 * @credits http://kenwheeler.github.io/slick/
 */

var slider = function () {
    var self = this;
    $window = $(window);

    self.initHeaderSlider = function () {
        $('.js-header-slider').slick({
            autoplay: true,
            speed: 800,
            useTransform: true,
            cssEase: 'ease-in-out'
        });
    };

    self.initLogoSlider = function () {
        $('.js-logo-slider').slick({
            prevArrow: '<button type="button" class="c-slider-arrow arrow-prev"><span class="c-icon c-icon-angle-down"></span></button>',
            nextArrow: '<button type="button" class="c-slider-arrow arrow-next"><span class="c-icon c-icon-angle-down"></span></button>',
            mobileFirst: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            autoplay: true,
            autoplaySpeed: 3000,
            useTransform: true,
            speed: 800,
            cssEase: 'ease-in-out',
            responsive: [
                {
                    breakpoint: getBreakpointSize('medium'),
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: getBreakpointSize('large'),
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5
                    }
                }
            ]
        });
    };

    /**
     * Slider for USPs on small resolutions
     * Author: Rianne Oosthoek <rianne@click.nl>
     */
    self.initUspSlider = function () {
        var $usp_slider = $('.js-usp-slider');
        var slider_settings = {
            mobileFirst: true,
            autoplay: true,
            speed: 500,
            useTransform: true,
            cssEase: 'ease-in-out',
            arrows: false,
            responsive: [
                {
                    breakpoint: getBreakpointSize('large') - 1,
                    settings: 'unslick'
                }
            ]
        };
        // Initiate slider
        $usp_slider.slick(slider_settings);

        // Re-init slider when resizing below large breakpoint.
        $window.on('load resize', function () {
            if ($window.width() < getBreakpointSize('large')) {
                if (!$usp_slider.hasClass('slick-initialized')) {
                    $usp_slider.slick(slider_settings);
                }
            }
        });
    };
};