/**
 * Setting subscription prices based on user choice
 */
var choose_subscription = function () {
    var self = this;

    /**
     * Initialize subscription prices
     *
     * @author  Joost Ligthart <joost@click.nl>
     * @return void
     */
    self.init = function (section_id) {
        let $period_toggle = $(`.js-subscription-period-toggle[data-section-id="${section_id}"]`);
        self.toggleSubscriptionPrices(section_id, $period_toggle.is(':checked'));

        $period_toggle.change(function (e) {
            e.preventDefault();
            self.toggleSubscriptionPrices(section_id, this.checked);
            self.toggleSubscriptionActiveText(section_id, this.checked);
        });
    };

    /**
     * Toggles the subscription prices between monthly and annually
     *
     * @author  Joost Ligthart <joost@click.nl>
     * @return void
     */
    self.toggleSubscriptionPrices = function (section_id, is_checked) {
        let period = 'monthly';
        // RO: Disabled by customer request via issue https://clickdotnl.atlassian.net/browse/LWSS-246
        // Save for future purposes
        // let period = is_checked ? 'annual' : 'monthly';
        $.get(`/api/choose-subscription/get-subscription-prices?period=${period}`, function (response) {
            let $error_field = $(`.js-alert-box[data-section-id="${section_id}"]`);
            $error_field.addClass('hide');
            // Get the new prices
            let new_prices = response;

            // Get the container div by section id
            var $price_field_containers = $(`.js-subscription-price-fields[data-section-id="${section_id}"]`);
            $price_field_containers.each(function (index, price_field_container) {

                let $price_field_container = $(price_field_container);
                $price_field_container.each(function (index, price_field_container) {
                    // Get the text element for the subscription price
                    let $price_field = $(price_field_container).find('.js-subscription-price');

                    // Get the text element for the subscription price suffix (showing if the price is annual or monthly)
                    let $price_field_suffix = $(price_field_container).find('.js-subscription-price-suffix');

                    // Get the correct price per price plan
                    let new_price = new_prices[$price_field.data('price-plan-id')];

                    // Update the text fields
                    $price_field.text(new_price);
                    $price_field_suffix.text(Lang.get(`frontend.js.choose_subscription.${period}_price_suffix`));
                });
            });
        }).fail(function (error) {
            let $error_field = $(`.js-alert-box[data-section-id="${section_id}"]`);
            $error_field.text(Lang.get('frontend.js.choose_subscription.something_went_wrong'));
            $error_field.removeClass('hide');
        });
    };

    /**
     * Toggles the active text color for subscription prices
     *
     * @author  Rianne Oosthoek <rianne@click.nl>
     * @return void
     */
    self.toggleSubscriptionActiveText = function (section_id, is_checked) {
        let $pay_annual = $(`.js-subscription-pay-annual[data-section-id="${section_id}"]`);
        let $pay_monthly = $(`.js-subscription-pay-monthly[data-section-id="${section_id}"]`);

        if (is_checked) {
            $pay_annual.removeClass('u-color-dark-gray');
            $pay_monthly.addClass('u-color-dark-gray');
        } else {
            $pay_monthly.removeClass('u-color-dark-gray');
            $pay_annual.addClass('u-color-dark-gray');
        }
    };
};