/**
 * Scroll to specific element after click event
 */

/**
 * Scroll down to the next element after clicking 
 * the button in the header
 *
 * @author Rianne Oosthoek
 */
var scroll_to_element = function(){
    var self = this;
    var $html_body = $('html, body');

    /**
     * Set listener on home page scroll spy
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     */
    self.setListenerScrollToNextElement = function(){
        var $scroll_trigger = $('.js-trigger-scroll-down');
        $scroll_trigger.click(function(){
            var offset_content = $('.js-content').offset().top;
            var offset_window = $(window).scrollTop();
            var offset_to_scroll = offset_content + offset_window;

            $html_body.animate({
                scrollTop: offset_to_scroll
            }, 1000);
        });
    };

    /**
     * Set listener on holes
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     */
    self.setListenerScrollToHole = function(){
        var $el = $('.js-trigger-scroll-spy');
        $el.click(function(e){
            e.preventDefault();

            var target_id = $(this).attr('href');
            var $target = $(target_id);
            var menu_height = $('.js-sticky-nav').height();

            // Scroll to target element minus the height of the sticky navigation
            $html_body.animate({
                scrollTop: $target.offset().top - menu_height
            }, 1000);

        });
    };

    /**
     * Scroll to form when hash exists
     * @param {*} hash 
     */
    self.scrollToFormWhenHashExists = function(hash, element)
    {
        if(window.location.hash == hash) {
            self.setListenerScrollToFormOnLoad(element); 
        }
    };

    /**
     * Scroll to form on load when querystring is present
     *
     * @author Edward van der Jagt <edward@click.nl> 
     * @edited by Rianne Oosthoek <rianne@click.nl>
     */
    self.setListenerScrollToFormOnLoad = function(element){
        var element = element || '.js-signup-form';
        $(window).on('load', function(){
            $html_body.animate({ 
                scrollTop: $(element).parentsUntil('section').parent().offset().top 
            }, 1000);
        });
    };

    /**
     * Scroll to form after click event
     *
     * @author Rianne Oosthoek <rianne@click.nl>
     */
    self.setListenerScrollToFormOnClick = function(){
        var $anchor = $('a[href*="#signup-form"]');
        $anchor.click(function(){
            $html_body.animate({ 
                scrollTop: $('.js-signup-form').parentsUntil('section').parent().offset().top 
            }, 1000);
        });
    };
};  