/**
 * Extract thumbnail from youtube or vimeo
 */

var embed_video = function() {
    var self = this;
    var $video_url_input = $('.js-video-url-input');

    self.init = function() {
        $video_url_input.on('change', function(){
            self.getVideoThumbnail($(this).val(), $('.js-video-thumb-img'));
        });

        if( $video_url_input.val() !== '' ){
            self.getVideoThumbnail($video_url_input.val(), $('.js-video-thumb-img'));
        }
    };

    /**
     * Retrieve video thumbnail from url
     *
     * @author Edward van der Jagt <edward@click.nl>
     * @param   {string}  url  URL of the video
     * @return  void
     */
    self.getVideoThumbnail = function(url, $target) {
        // Obtains the video's thumbnail and passed it back to a callback function.
        var video_object = self.parseVideo(url);
        if (video_object.type === 'youtube') {
            self.setThumbnail('//img.youtube.com/vi/' + video_object.id + '/hqdefault.jpg', $target);
        } else if (video_object.type === 'vimeo') {
            // Requires jQuery
            $.get('http://vimeo.com/api/v2/video/' + video_object.id + '.json', function(data) {
                self.setThumbnail(data[0].thumbnail_large, $target);
            });
        }
    };

    /**
     * Add thumbnail url to the img src and show the thumbnail in the admin
     *
     * @author Edward van der Jagt <edward@click.nl>
     * @param   {string}  src  URL of the video
     */
    self.setThumbnail = function(src, $target) {
        $target.attr('src',src);
        // Show thumbnail
        if( $target.parent('.js-video-thumb').length > 0 ){
            $target.parent('.js-video-thumb').removeClass('hide');
        }
    };

    self.setThumbnailWebsite = function($video_wrapper, url) {
        // Set thumbnail
        var $video_thumb = $video_wrapper.find('.js-video-thumb-img');

        // Only request thumbnail if it isn't already retrieved
        if( $video_thumb.attr('src') === '' ){
            self.getVideoThumbnail( url, $video_thumb );
        }
    };

    /**
     * Parse video url
     *
     * - Supported YouTube URL formats:
     *   - http://www.youtube.com/watch?v=My2FRPA3Gf8
     *   - http://youtu.be/My2FRPA3Gf8
     *   - https://youtube.googleapis.com/v/My2FRPA3Gf8
     * - Supported Vimeo URL formats:
     *   - http://vimeo.com/25451551
     *   - http://player.vimeo.com/video/25451551
     * - Also supports relative URLs:
     *   - //player.vimeo.com/video/25451551
     * 
     * @author Edward van der Jagt <edward@click.nl>
     * @param   {string}  url  URL that needs to be parsed
     * @return  {object}
     */
    self.parseVideo = function(url) {
        url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

        if (RegExp.$3.indexOf('youtu') > -1) {
            var type = 'youtube';
        } else if (RegExp.$3.indexOf('vimeo') > -1) {
            var type = 'vimeo';
        }

        return {
            type: type,
            id: RegExp.$6
        };
    };
};