/**
 * Navigation.js
 *
 * All functionality regarding the navigation
 */

/**
 * All functionality regarding the navigation
 *
 * @author Rianne Oosthoek
 * @return void
 */
var navigation = function () {
    var self = this;
    var $off_canvas_overlay = $('.js-off-canvas-overlay');
    var $nav_icon = $('.js-nav-icon');
    var $nav_inner = $('.js-nav-inner');

    self.init = function () {
        self.closeSideMenuOnResize();
        self.setListenerSubNav();
        self.toggleSubNav($('.js-nav-anchor.is-active'));

        // Animate icon when closing menu by clicking on overlay
        $off_canvas_overlay.click(function () {
            self.animateNavIcon();
        });

        // Animatie icon when clicking on it
        $nav_icon.click(function () {
            self.animateNavIcon();
        });
    };

    /**
     * Animatie the nav icon from hamburger to cross and back
     *
     * @author Rianne Oosthoek
     * @return void
     */
    self.animateNavIcon = function () {
        if ($nav_inner.hasClass('is-active')) {
            $nav_inner.removeClass('is-active');
        } else {
            $nav_inner.addClass('is-active');
        }
    };

    /**
     * Close side menu when reaching desktop breakpoint
     *
     * @author Rianne Oosthoek
     * @return void
     */
    self.closeSideMenuOnResize = function () {
        var window_width = getWindowWidth();

        $window.on('orientationchange resize', function () {
            window_width = getWindowWidth();

            // Close side menu when entering large screensize
            if (window_width > getBreakpointSize('large') && $off_canvas_overlay.hasClass('is-visible')) {
                $off_canvas_overlay.trigger('click');
            }
        });
    };

    /**
     * Set listener to toggle sub nav
     *
     * @author Rianne Oosthoek
     */
    self.setListenerSubNav = function () {
        $('.js-nav-anchor').click(function (e) {
            // Disable normal behaviour of anchor
            e.preventDefault();
            self.toggleSubNav($(this));
        });
    };

    /**
     * Toggle sub nav
     *
     * @author Rianne Oosthoek
     * @param  {jQuery} $el parent anchor
     * @return void
     */
    self.toggleSubNav = function ($el) {
        var $sub_nav = $el.next('.js-nav-sub');
        var show_sub = true;
        // Check if sub_nav already has is-active class, if so, we only want to slideUp, not slideDown
        if ($sub_nav.hasClass('is-active')) {
            show_sub = false;
        }
        // Slide up all open sub navs
        $('.js-nav-sub').slideUp().removeClass('is-active');
        // If sub needs to slideDown
        if (show_sub) {
            // Slide down selected subnav
            $sub_nav.slideDown().addClass('is-active');
        }
    };
};
