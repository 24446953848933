/**
 * Toggle subitems
 */

var toggle_subitems = function() {
    var self                = this;
    var $toggle_sub_items   = $('.js-toggle-sub-items');
    var $sub_items          = $('.js-sub-items');
    var $radio_button       = $('.js-radio-label');
    var $error              = $('.js-payment-message');
    var locale              = $('.js-locale');
    var $radio_opt          = $('.js-payment-opt-2');

    self.init = function() {
        // Attach click event to radio buttons
        $toggle_sub_items.click(function(){
            self.toggleSubItems( $(this).attr('id') );
        });

        // Show correct upload field after post fails
        if( $toggle_sub_items.filter(':checked').length > 0 ){
            self.toggleSubItems( $toggle_sub_items.filter(':checked').attr('id') );
            $sub_items.removeClass('hide');
        }

        else {
            $sub_items.addClass('hide');
        }

        // $radio_button.click(function() {
        //     self.showMessage();
        // });
    };

    /**
     * Show correct upload field
     *
     * @author Rianne Oosthoek (altered by Hasan Özaynaci)
     * @param  {string} id  id of the selected upload element
     */
    self.toggleSubItems = function( id ) {
        var $selected_sub_items = $('.js-sub-items').filter('[data-type="' + id + '"]' );
        var $sub_items = $('.js-sub-items').not( $selected_sub_items );
    
        // Show media upload field
        $selected_sub_items.removeClass('hide');
        if( !$sub_items.hasClass('hide') ) {
            // Hide other upload field
            $sub_items.addClass('hide');
        }
        // show sub items
        if($toggle_sub_items.is(':checked')) {
            $('.js-sub-items').removeClass('hide');
            $error.removeClass('hide');
        }

        else {
            $('.js-sub-items').addClass('hide');
            $error.addClass('hide');
        }

    };

    /**
     * Show message
     *
     * @author Hasan Ozaynaci
     * @return void
     */

    self.showMessage = function()
    {
        // show message based on language of form
        if(locale.val() == 'nl') {
            if($radio_opt.is(':checked')) {
                $error.text('Als de betaling niet op tijd heeft plaatsgevonden, zal Golf Amsteldijk de automatische incasso gebruiken.');
            } else {
                $error.text('');
            }

        // if language is other than dutch
        } else {
            if($radio_opt.is(':checked')) {
                $error.text('If payment had not been made on time, Golf Amsteldijk will use the direct debit.');
            } else {
                $error.text('');
            }
        }

    };
};
